import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms'
import { PlantserviceService } from '../shared/services/plantservice/plantservice.service';
// import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit {

  cityList: any = [];
  tempForecastDay: any = {};
  timerInterval: any;
  pumps: any;
  valves: any;
  newPumpId: any;
  newValveId: any;
  constructor(private plantlist: PlantserviceService) { }

  getCityList() {
    this.plantlist.getCityList().subscribe((data) => {
      this.cityList = data;
    })
  }

  ngOnInit() {
    this.getCityList();
    this.timerInterval = setInterval(() => {
      this.getCityList();
    }, 30000);
  }

  ngOnDestroy() {
    clearInterval(this.timerInterval);
  }

  // modify plant health
  plantHealthFormGroup = new FormGroup({
    id: new FormControl(),
    health: new FormControl()
  });
  // modify water level
  plantWaterLevelFormGroup = new FormGroup({
    id: new FormControl(),
    waterLevel: new FormControl()
  });
  // modify forecast data
  plantForecastFormGroup = new FormGroup({
    id: new FormControl(),
    setCurrentPrep: new FormControl(),
    setForecastPrep: new FormArray([new FormControl('', Validators.required)])
  });
   // modify raw Water Storage data
  rawWaterStorageFormGroup = new FormGroup({
    id: new FormControl(),
    rawWaterStorage: new FormControl()
  });
    // modify treated Water Storage data
  treatedWaterStorageFormGroup = new FormGroup({
    id: new FormControl(),
    treatedWaterStorage: new FormControl()
  });
    // modify average Consumption PerDay data
  averageConsumptionPerDayFormGroup = new FormGroup({
    id: new FormControl(),
    averageConsumptionPerDay: new FormControl()
  });
    // modify Pump status data
  pumpStatusFormGroup = new FormGroup({
    id: new FormControl(),
    pumpId: new FormControl(),
    pumpStatus: new FormControl()
  });
  // modify valve status data
  valveStatusFormGroup = new FormGroup({
    id: new FormControl(),
    valveId: new FormControl(),
    valveStatus: new FormControl()
  });
  // modify Reservoir Process Maintenance data
  reservoirProcessMaintenanceFormGroup = new FormGroup({
    id: new FormControl(),
    processMaintainance: new FormControl()
  });
  // modify reservoir Next Rain Forecast data
  reservoirNextRainForecastFormGroup = new FormGroup({
    id: new FormControl(),
    rainForecast: new FormControl()
  });

  setPlantHealth() {
    console.log(this.plantHealthFormGroup.value);

    var h = this.plantHealthFormGroup.value.health
    if (h == 'true') {
      h = true
    } else {
      h = false
    }

    this.plantlist.updatePlantHealth({ id: this.plantHealthFormGroup.value.id, fdata: h }).subscribe((data) => {
      console.log(JSON.stringify(data))
    });
    this.plantHealthFormGroup.reset();
  };

  setWaterLevel() {
    console.log(this.plantWaterLevelFormGroup.value);
    this.plantlist.updateReservoirLevel({ id: this.plantWaterLevelFormGroup.value.id, fdata: this.plantWaterLevelFormGroup.value.waterLevel }).subscribe((data) => {
      console.log(data)
    });
    this.plantWaterLevelFormGroup.reset();
  }

  setRawWaterStorage() {
    console.log(this.rawWaterStorageFormGroup.value);
    this.plantlist.updateRawWaterStorage({ id: this.rawWaterStorageFormGroup.value.id, fdata: this.rawWaterStorageFormGroup.value.rawWaterStorage }).subscribe((data) => {
      console.log(data)
    });
    this.rawWaterStorageFormGroup.reset();
  }

  setTreatedWaterStorage() {
    console.log(this.treatedWaterStorageFormGroup.value);
    this.plantlist.updateTreatedWaterStorage({ id: this.treatedWaterStorageFormGroup.value.id, fdata: this.treatedWaterStorageFormGroup.value.treatedWaterStorage }).subscribe((data) => {
      console.log(data)
    });
    this.treatedWaterStorageFormGroup.reset();
  }

  setAverageConsumptionPerDay() {
    console.log(this.averageConsumptionPerDayFormGroup.value);
    this.plantlist.updateAverageConsumptionPerDay({ id: this.averageConsumptionPerDayFormGroup.value.id, fdata: this.averageConsumptionPerDayFormGroup.value.averageConsumptionPerDay }).subscribe((data) => {
      console.log(data)
    });
    this.averageConsumptionPerDayFormGroup.reset();
  }

  selectPumpOption(id) {
    this.newPumpId = id;
    this.getPumpData();
  }
  getPumpData() {
    this.cityList.forEach((element) => {
      if (element.id == this.newPumpId) {
        this.pumps = element.assets.Pump;
      }
    });
  }
  setPumpStatus() {
    console.log(this.pumpStatusFormGroup.value);
    this.cityList.forEach((element) => {
      if (element.id == this.pumpStatusFormGroup.value.id) {
        element.assets.Pump.find((ele) => {
          if (ele.id == this.pumpStatusFormGroup.value.pumpId) {
            // ele.status = this.pumpStatusFormGroup.value.pumpStatus;
            ele.status = (this.pumpStatusFormGroup.value.pumpStatus === 'true');
            this.plantlist.updatePumpStatus(element).subscribe((data) => {
              console.log(data);
            });
            this.pumpStatusFormGroup.reset();
          }
        });
      }
    });
  };


  selectValveOption(id) {
    this.newValveId = id;
    this.getValveData();
  }
  getValveData() {
    this.cityList.forEach((element) => {
      if (element.id == this.newValveId) {
        this.valves = element.assets.Valve;
      }
    });
  }
  setValveStatus() {
    console.log(this.valveStatusFormGroup.value);
    this.cityList.forEach((element) => {
      if (element.id == this.valveStatusFormGroup.value.id) {
        element.assets.Valve.find((ele) => {
          if (ele.id == this.valveStatusFormGroup.value.valveId) {
            // ele.status = this.valveStatusFormGroup.value.pumpStatus;
            ele.status = (this.valveStatusFormGroup.value.valveStatus === 'true');
            this.plantlist.updateValveStatus(element).subscribe((data) => {
              console.log(data);
            });
            this.valveStatusFormGroup.reset();
          }
        });
      }
    });
  }

  setReservoirProcessMaintenance(){
    console.log(this.reservoirProcessMaintenanceFormGroup.value);
    this.plantlist.updateProcessMaintenanceDate({ id: this.reservoirProcessMaintenanceFormGroup.value.id, fdata: this.reservoirProcessMaintenanceFormGroup.value.processMaintainance }).subscribe((data) => {
      console.log(data)
    });
    this.reservoirProcessMaintenanceFormGroup.reset();
  }

  setNextRainForecast(){
    console.log(this.reservoirNextRainForecastFormGroup.value);
    this.cityList.forEach((element) => {
      if (element.id == this.reservoirNextRainForecastFormGroup.value.id) {
        element.forecast.nextRainForcast = this.reservoirNextRainForecastFormGroup.value.rainForecast;
        this.plantlist.updateNextRainForecastDate(element).subscribe((data) => {
          console.log(data);
        });
      }
    });
    this.reservoirNextRainForecastFormGroup.reset();
  }

  forecastIdSel() {
    this.tempForecastDay = {}
    console.log(this.plantForecastFormGroup.value);
    var id = parseInt(this.plantForecastFormGroup.value.id)
    if (this.cityList.length <= 0) {
      return
    }

    this.cityList.forEach((item) => {
      console.log(`typeof id: ${typeof (item.id)} type of id: ${typeof (id)}`)
      if (item.id == id) {
        console.log(`item id matched: ${item.id}`)
        this.tempForecastDay = item.forecast
        //const s = this.plantForecastFormGroup['setForecastPrep']
        //s.push(new FormControl('', Validators.required))
      }
    });

    //console.log(`>>>>>>>>> ${JSON.stringify(this.tempForecastDay)}`)

  }

  modifyForecast() {
    console.log(this.plantForecastFormGroup.value);
  }


}
