import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LoginuserdataService } from '../userdata/loginuserdata.service';
@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private http: HttpClient,private uData:LoginuserdataService) { }
  public token: string;
  private toHttpPatams(obj) {
    if (obj) {
      let params: HttpParams = new HttpParams();
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const val = obj[key];
          params = params.append(key, val);
        }
      }
      return params;
    } else {
      return undefined;
    }
  }

  /* for Beared token */
  private prepareOptions(params?): any {
    let userDetails: any =this.uData.getNewData();// JSON.parse(localStorage.getItem('user'));
    this.token=userDetails.token;
    let headers = new HttpHeaders();  
   headers = headers.append('Content-Type', 'application/json');
   headers=headers.append('Authorization', `Bearer ${this.token}`)
    return { headers, params };
  }

  get(url:string,params?:object) {
    const options = this.prepareOptions(this.toHttpPatams(params));
    return this.http.get(`${environment.authapi}${url}`,options);
  }

  post(url:string,params?:object) {
    const options = this.prepareOptions();
    return this.http.post(`${environment.authapi}${url}`, params, options);
  }
  delete(url:string,params?:object)
  {
    const options = this.prepareOptions(this.toHttpPatams(params));
    return this.http.delete(`${environment.authapi}${url}`, options);
  }
}
