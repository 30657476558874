import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from './shared/services/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { User } from './layout/Model/work-order-new.model';
import { RegisterViewModel } from './layout/Model/user-information.model';
import { MsalService } from '@azure/msal-angular';
import { WellserviceService } from './shared/services/wellservice/wellservice.service';
import IdleTimer from "./signup/Idle-Timer.js";
import { PlantserviceService } from 'src/app/shared/services/plantservice/plantservice.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginuserdataService } from './shared/services/userdata/loginuserdata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public loading = false;
  user: User;
  isError: boolean;
  errorMessage: string;
  lstobjuservales: any = [];
  timer: any;
  isTokenAvailable: boolean = false;
  @ViewChild('content') content: any;
  closeResult = '';
  ngOnInit(): void {

    localStorage.setItem("flagDynamicOrStatic", "static");
    this.dashboard.changeDashboard('dashboard');
    localStorage.setItem('isLoggedin', 'true');
    let objRegisterUser = new RegisterViewModel();
    objRegisterUser.id = "";
    objRegisterUser.UserName = this.user.userName;
    objRegisterUser.Userid = "";
    objRegisterUser.Password = this.user.password;
    objRegisterUser.lstRolename = [];
    objRegisterUser.ConfirmPassword = "";

    const tokenRequest: any = {
      scopes: ['openid', 'profile', 'user.read']
    };


    //admin
    this.dashboard.setCurrentUserRole("ADMIN");
    // this.dashboard.changeDashboard('admin');
    //localStorage.setItem("dashboardType", 'admin');

    localStorage.setItem("dashboardType", 'dashboard');

    localStorage.setItem('isLoggedin', 'true');
    //localStorage.setItem('user', JSON.stringify(this.getFeatures(this.user.userName)));
    this.uData.setNewData(this.getFeatures(this.user.userName, 'Admin', 'admin'));
    // this.router.navigateByUrl('/apexcockpit');

    const account = this.malauthService.getAccount();
    if (account == null) {
      localStorage.clear()
      //  this.malauthService.loginRedirect(tokenRequest).then((idToken: any) => { });
      // this.malauthService.loginRedirect();
    }
    else {
      //localStorage.setItem('user-mail',account.userName);
      this.wellService.userSignIn(true);
      this.router.navigate(['/global']);
    }


    this.malauthService
      .acquireTokenSilent(tokenRequest)
      .then((tokenResponse: any) => {
        // Callback code here
        if (tokenResponse.accessToken) {
          localStorage.setItem('azureToken', tokenResponse.accessToken);
          localStorage.setItem('azureTokenExpiry', tokenResponse.expiresOn);
          this.plantService.getRole().subscribe((role: any) => {
            if (role && role.length > 0) {
              localStorage.setItem('roleId', role[0].role_id)
            } else {
              localStorage.setItem('roleId', '1');
            }
            this.isTokenAvailable = true;
            this.wellService.userSignIn(true);
            this.router.navigate(['/global']);
          }, (err) => {

          });

        }
      })
      .catch(error => {
        console.log('Inside cache block ',localStorage.getItem('azureTokenExpiry'), error)
        let currentTime = new Date();
        if (localStorage.getItem('azureTokenExpiry')) {
          let tokenExpiryDate = new Date(localStorage.getItem('azureTokenExpiry'));
          if (currentTime <= tokenExpiryDate && account) {
            this.router.navigate(['/global']);
          }
          else {
            this.open(this.content);
          }
        }


      });

    this.timer = new IdleTimer({
      timeout: 21600, //expired after 30 min
      onTimeout: () => {
        // this.title = "Timeout";
        this.logout();
      }
    });
  }
  constructor(private router: Router, private wellService: WellserviceService,
    private dashboard: DashboardService, private uData: LoginuserdataService, private malauthService: MsalService,
    private plantService: PlantserviceService, private modalService: NgbModal) {
    this.user = new User();
    this.isError = false;
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  redirect() {
    this.logout();
  }
  getFeatures(pusername: any, fullName, role): any {
    return {
      id: "ed645b40-216b-4143-98c0-d661f386af20",
      username: pusername,
      fullname: fullName,
      role: role,
      //password: "AQAAAAEAACcQAAAAEIRVjGW9u+vK6pX/J9ZBqxZxC2R9qRsMjTxRn27hSW+sJQxXzf3fmCBoUv5asG6o/g==",
      lstRolename: [{
        roleName: "ADMIN",
        features: [
          {
            id: "c0c7ad28-72ff-aae8-71cd-73ce2c33a5a7", featureId: "55",
            featureName: "Alert Rule", featureMenuName: "Alert Rule", featureRoute: "alertrule", dashboardName: "./alertrule/alertrule.module#AlertRuleModule"
          },
          {
            id: "19e95dad-a6e6-ac23-1a42-6aa85c7fe4e4", featureId: "51",
            featureName: "Alert Rule Add", featureMenuName: "Alert Rule",
            featureRoute: "", dashboardName: ""
          },
          {
            id: "ed7dad52-1202-c27f-0e16-7842c4614639", featureId: "53",
            featureName: "Alert Rule Delete", featureMenuName: "Alert Rule", featureRoute: "", dashboardName: ""
          },
          {
            id: "de1675e1-45d1-d28a-876e-3f3e4a4b38eb", featureId: "52",
            featureName: "Alert Rule Edit", featureMenuName: "Alert Rule", featureRoute: "", dashboardName: ""
          },
          {
            id: "5b028ef6-d287-cd43-00cc-c5edaedaf46c", featureId: "54",
            featureName: "Alert Rule View", featureMenuName: "Alert Rule", featureRoute: "alertrule", dashboardName: "./alertrule/alertrule.module#AlertRuleModule"
          },
          {
            id: "e7de8a33-5049-2279-5b65-a890b1945b7a", featureId: "7",
            featureName: "Alerts", featureMenuName: "Alerts", featureRoute: "alert", dashboardName: "./alert/alert.module#AlertModule"
          },
          {
            id: "862c8be6-c382-e72f-cbf6-58ec5933f1b5", featureId: "15",
            featureName: "App Engine", featureMenuName: "APP Engine", featureRoute: "appengine", dashboardName: "./appengine/appengine.module#AppengineModule"
          },
          {
            id: "d77fcf87-971d-5a97-ff49-6d635cfd3637", featureId: "47",
            featureName: "App Engine Add", featureMenuName: "APP Engine", featureRoute: "", dashboardName: ""
          },
          {
            id: "d1243dda-68bf-d44d-215b-5b544dea3c37", featureId: "49",
            featureName: "App Engine Delete", featureMenuName: "APP Engine", featureRoute: "", dashboardName: ""
          },
          {
            id: "1c412392-23a7-e4ad-d49d-b9803cc69253", featureId: "48",
            featureName: "App Engine Edit", featureMenuName: "APP Engine", featureRoute: "", dashboardName: ""
          },
          {
            id: "cffe8b94-1bff-c63d-0b44-9643f699a32a",
            featureId: "50",
            featureName: "App Engine View", featureMenuName: "APP Engine", featureRoute: "appengine",
            dashboardName: "./appengine/appengine.module#AppengineModule"
          },
          {
            id: "42e31d4a-720b-c9b3-d077-65be33eb46ef",
            featureId: "9", featureName: "Asset Health", featureMenuName: "Asset Health", featureRoute: "prism",
            dashboardName: "./prism/prism.module#PrismModule"
          },
          {
            id: "f3f2ee68-348d-671a-1986-fcb005fde7b1",
            featureId: "16", featureName: "Asset Management", featureMenuName: "Asset management", featureRoute: "asset",
            dashboardName: "./asset/assetmanagement.component"
          },
          {
            id: "4f713807-4d20-fa12-010d-a8446d0a779a",
            featureId: "14", featureName: "Calc Engine", featureMenuName: "Calc Engine", featureRoute: "calc",
            dashboardName: "./calcengine/calcengine.module#CalcengineModule"
          },
          {
            id: "2f63fadc-ba8f-ec82-2f42-4f209977fe6f",
            featureId: "43", featureName: "Calc Engine Add", featureMenuName: "Calc Engine", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "27bf901e-1a97-f095-ccaf-804617842f6c",
            featureId: "45", featureName: "Calc Engine Delete", featureMenuName: "Calc Engine", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "9e2bfd30-d89d-9272-6113-7c5bf0c2f155",
            featureId: "44", featureName: "Calc Engine Edit", featureMenuName: "Calc Engine", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "b878c9a6-1f06-c9ae-95d2-452c347938b3",
            featureId: "46", featureName: "Calc Engine View", featureMenuName: "Calc Engine", featureRoute: "calc",
            dashboardName: "./calcengine/calcengine.module#CalcengineModule"
          },
          {
            id: "d35a8a91-c59f-3b53-d4b7-38322f487bd1",
            featureId: "13", featureName: "Constants", featureMenuName: "Constants", featureRoute: "constants",
            dashboardName: "./constants/constants.component#PlantconfigurationModule"
          },
          {
            id: "4627e111-a23f-e9e6-bccc-7811f29ab746",
            featureId: "39", featureName: "Constants Add", featureMenuName: "Constants", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "2939f0b4-220c-73a3-e86a-8a44522b8b99",
            featureId: "41", featureName: "Constants Delete", featureMenuName: "Constants", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "34039ea3-68fb-7b36-f6ed-cfa89fcbb91d",
            featureId: "40", featureName: "Constants Edit", featureMenuName: "Constants", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "ce346fb2-06be-1827-de9c-5697a311787b",
            featureId: "42", featureName: "Constants View", featureMenuName: "Constants", featureRoute: "constants",
            dashboardName: "./constants/constants.component#PlantconfigurationModule"
          },
          {
            id: "4af716df-373d-d390-9579-094c18c14405",
            featureId: "22", featureName: "Identity Management", featureMenuName: "Identity Management", featureRoute: "identityconfig",
            dashboardName: "./identityconfig/identityconfig.module#IdentityconfigModule"
          },
          {
            id: "42252bf1-3e9f-17ba-c603-ace158f8d310",
            featureId: "8", featureName: "Inventory", featureMenuName: "Inventory", featureRoute: "inventory",
            dashboardName: "./inventory/inventory.module#InventoryModule"
          },
          {
            id: "3395ea34-9216-6aec-995d-8dcdbd0fdf71",
            featureId: "10", featureName: "Metrics", featureMenuName: "Metrics", featureRoute: "quality",
            dashboardName: "./quality/quality.module#QualityModule"
          },
          {
            id: "97489e44-ac29-1be0-f8ea-300e2e350cea",
            featureId: "2", featureName: "Operations", featureMenuName: "Operations", featureRoute: "workorderview",
            dashboardName: "./work-order-view/work-order-view.module#WorkOrderViewModule"
          },
          {
            id: "1d4ea9a4-33e7-9d72-3308-662f9007001d",
            featureId: "5", featureName: "Partanalytics", featureMenuName: "Partanalytics", featureRoute: "partanalytics",
            dashboardName: "./partanalytics/partanalytics.module#PartAnalyticsModule"
          },
          {
            id: "ace5952f-7c1f-e30f-42eb-a5ed4a12c88b",
            featureId: "11", featureName: "Plant Information", featureMenuName: "Plant Information", featureRoute: "info",
            dashboardName: "./plantconfiguration/plantconfiguration.module#PlantconfigurationModule"
          },
          {
            id: "3e05ca13-b497-40df-a9c2-8ef6d5838619",
            featureId: "31", featureName: "Plant Information Add", featureMenuName: "Plant Information", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "c119b9c9-b5b6-7eed-5ad2-119fc21686f1",
            featureId: "33", featureName: "Plant Information Delete", featureMenuName: "Plant Information", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "69a9fabc-9eaa-d7a4-e3f7-3b324d273d4f",
            featureId: "32", featureName: "Plant Information Edit", featureMenuName: "Plant Information", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "a3156844-eb2d-ab3f-4763-77bb817273de",
            featureId: "34", featureName: "Plant Information View", featureMenuName: "Plant Information", featureRoute: "info",
            dashboardName: "./plantconfiguration/plantconfiguration.module#PlantconfigurationModule"
          },
          {
            id: "0f7b41ee-9e8e-80e5-b3d0-76b6852e25f9",
            featureId: "1", featureName: "PlantDashBoard", featureMenuName: "PlantDashBoard", featureRoute: "operations",
            dashboardName: "./operation/operation.module#OperationModule"
          },
          {
            id: "0f7b41ee-9e8e-80e5-b3d0-76b6852e25f8",
            featureId: "1", featureName: "ValueStream", featureMenuName: "ValueStream", featureRoute: "ValueStream",
            dashboardName: "./value-stream/value-stream.module#ValueStreamModule"
          },
          {
            id: "da095225-7aa3-8d2e-5d24-fce2ccbc4331",
            featureId: "3", featureName: "Process", featureMenuName: "Process", featureRoute: "process",
            dashboardName: "./process/process.module#ProcessModule"
          },
          {
            id: "9b1674c9-5744-d32b-fdec-483dbfee3761",
            featureId: "17", featureName: "Rest Services", featureMenuName: "Rest Services", featureRoute: "rest",
            dashboardName: "./rest/assetrestconfig.component"
          },
          {
            id: "9e0000bd-9b66-914c-2023-eac8d37a2e4c",
            featureId: "21", featureName: "Role Management", featureMenuName: "Role Management", featureRoute: "usergroupmanagement",
            dashboardName: "./usergroupmanagement/usergroupmanagement.module#UsergroupmanagementModule"
          },
          {
            id: "238aafbe-60e0-b656-5bc1-58a69dacfd72",
            featureId: "27", featureName: "Role Management Add", featureMenuName: "Role Management", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "168e9c9e-171c-da63-9d8d-a9e375cf1443",
            featureId: "29", featureName: "Role Management Delete", featureMenuName: "Role Management", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "d9439c62-d19a-c97b-0baa-2bae2fe0d941",
            featureId: "28", featureName: "Role Management Edit", featureMenuName: "Role Management", featureRoute: "admin/usergroupmanagement",
            dashboardName: "./usergroupmanagement/usergroupmanagement.module#UsergroupmanagementModule"
          },
          {
            id: "743b2bd1-6e16-77a6-6263-a30b3abe2ee8",
            featureId: "30", featureName: "Role Management View", featureMenuName: "Role Management", featureRoute: "usergroupmanagement",
            dashboardName: "./usergroupmanagement/usergroupmanagement.module#UsergroupmanagementModule"
          },
          {
            id: "fc710075-b580-e5a8-9c58-fcce70a1d022",
            featureId: "6", featureName: "Rul", featureMenuName: "Rul", featureRoute: "rul",
            dashboardName: "./rul/rul.module#RulModule"
          },
          {
            id: "9bb4e09b-fdb0-bb99-f1b3-40d4a484b80e",
            featureId: "12", featureName: "shifts", featureMenuName: "shifts", featureRoute: "shifts",
            dashboardName: "./shifts/shifts.component#PlantconfigurationModule"
          },

          {
            id: "4c4402a5-87e0-7b7c-c478-3b2754f9791c",
            featureId: "35", featureName: "shifts Add", featureMenuName: "shifts", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "99ee1c48-8a61-b61e-d86b-49cbbcb902e3",
            featureId: "37", featureName: "shifts Delete", featureMenuName: "shifts", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "e8d4d441-2850-8419-9dfc-878c435cc1c4",
            featureId: "36", featureName: "shifts Edit", featureMenuName: "shifts", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "40ba0fff-c488-5dc1-6aca-40e212519166",
            featureId: "38", featureName: "shifts View", featureMenuName: "shifts", featureRoute: "shifts",
            dashboardName: "./shifts/shifts.component#PlantconfigurationModule"
          },
          {
            id: "d8b03cff-7b4c-f512-9cac-a9b7a728970b",
            featureId: "20", featureName: "User Management", featureMenuName: "User Management", featureRoute: "usermanagement",
            dashboardName: "./usermanagement/usermanagement.module#UsermanagementModule"
          },
          {
            id: "25c351c9-c22b-5a54-3c41-35e4d2029918",
            featureId: "23", featureName: "User Management Add", featureMenuName: "User Management", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "8aad1b1f-a51d-4ce3-f059-8928dad73b76",
            featureId: "25", featureName: "User Management Delete", featureMenuName: "User Management", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "f89c82ab-33d7-a419-7c53-55e6b1763ca3",
            featureId: "24", featureName: "User Management Edit", featureMenuName: "User Management", featureRoute: "",
            dashboardName: ""
          },
          {
            id: "8e0107ab-be4d-bb51-4ff3-24895899c1e4",
            featureId: "26", featureName: "User Management View", featureMenuName: "User Management", featureRoute: "usermanagement",
            dashboardName: "./usermanagement/usermanagement.module#UsermanagementModule"
          },
          {
            id: "f13c37eb-f1f3-d09a-6838-94a10e348a0a",
            featureId: "4", featureName: "WorkOrder", featureMenuName: "WorkOrder", featureRoute: "workchart",
            dashboardName: "./workchart/workchart.module#WorkChartModule"
          },
        ]
      }],
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1N…C8ifQ.n9EiJnc2FYC73b6HkX9RsP5LrObFq0femplRmLBm5R8"
    }

  }

  logout() {
    sessionStorage.removeItem('user');
    this.wellService.userSignIn(false);
    this.malauthService.logout();

  }

}
