import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SettabWorkeOrderNumberService {

  private getCurrentWorkOrder = new BehaviorSubject("undefined");
  currentWorkOrderNum = this.getCurrentWorkOrder.asObservable();

  private getCurrenttabActive = new BehaviorSubject("dashboard");
  currentActiveTab = this.getCurrenttabActive.asObservable();

  constructor() { }

  SetSelectedWorkOrderNum(Wordernumber: string)
  {
    this.getCurrentWorkOrder.next(Wordernumber);
  }


  SetActiveTab(activetab: string)
  {
    this.getCurrenttabActive.next(activetab);
  }

}
