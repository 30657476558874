import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedAssetServiceService {
  private getCurrentAsset = new BehaviorSubject('CFBRH-01');
  currentMessage = this.getCurrentAsset.asObservable();

  private getCurrentRoute = new BehaviorSubject('operations');
  currentRoute = this.getCurrentRoute.asObservable();

  private getCurrentSetLocation = new BehaviorSubject('');
  currentLocation = this.getCurrentSetLocation.asObservable();

  private getCurrentSetPlant = new BehaviorSubject('CFBPLANT');
  currentPlant = this.getCurrentSetPlant.asObservable();

  private getCurrentSetPlantID = new BehaviorSubject('');
  currentPlantID = this.getCurrentSetPlantID.asObservable();

  private getCurrentSetvaluestream = new BehaviorSubject('');
  currentValuestream = this.getCurrentSetvaluestream.asObservable();

  private getCurrentQualityGraphName=new BehaviorSubject('ScrapRate');
  currentQualityGraphname=this.getCurrentQualityGraphName.asObservable();

  private getCurrentProcessGraphName=new BehaviorSubject('OEE');
  currentProcessGraph=this.getCurrentProcessGraphName.asObservable();

  private getHistoryGraphName=new BehaviorSubject('');
  HistoryGraphname=this.getHistoryGraphName.asObservable();
  

  constructor() { }

  changeMessage(assetId: string) {
    this.getCurrentAsset.next(assetId)
  }

  SetSelectedRoute(route: string)
  {
    this.getCurrentRoute.next(route);
  }

  SetSelectedLocation(location:string)
  {
    this.getCurrentSetLocation.next(location);
  }

  setSelectedPlant(plant:string)
  {
    this.getCurrentSetPlant.next(plant);
  }

  setSelectedPlantID(plantId:string)
  {
    this.getCurrentSetPlantID.next(plantId);
  }

  setSelectedvaluestream(valuestream:string)
  {
    this.getCurrentSetvaluestream.next(valuestream);
  }

  setQualityGraph(graphname:string)
  {
    this.getCurrentQualityGraphName.next(graphname);
  }

  setProcessGraph(graphname:string)
  {
    this.getCurrentProcessGraphName.next(graphname);
  }

  setHistoryGraph(graphname:string)
  {
    this.getHistoryGraphName.next(graphname);
  }


}
