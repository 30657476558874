import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WellserviceService {


  isAGMMarker:boolean = true;
  notificationType:any;
  private directDashboard = new BehaviorSubject<boolean>(false);
  dDashboard = this.directDashboard.asObservable();

  private getBreadcrumb = new BehaviorSubject([]);
  getBreadcrumbPath = this.getBreadcrumb.asObservable();

  private getCurrentWell = new BehaviorSubject('');
  currentMessage = this.getCurrentWell.asObservable();

  private getConnect = new BehaviorSubject(false);
  connect = this.getConnect.asObservable();

  private getEquipmentId = new BehaviorSubject('');
  equipmentId = this.getEquipmentId.asObservable();

  private getCurrentTabid = new BehaviorSubject('parent');
  currentTabId = this.getCurrentTabid.asObservable();


  private getCurrentRoute = new BehaviorSubject('pages');
  currentRoute = this.getCurrentRoute.asObservable();

  private getPlantEquipments = new BehaviorSubject('');
  plantEquipments = this.getPlantEquipments.asObservable();

  private getSelectedPlantId = new BehaviorSubject('');
  selectedPlantId = this.getSelectedPlantId.asObservable();

  private getNotificationInfo = new BehaviorSubject('');
  notificationInfo = this.getNotificationInfo.asObservable();

  private getNormalizedTagId = new BehaviorSubject('');
  normalizedTagId = this.getNormalizedTagId.asObservable();

  private getSelectedHierarchyType = new BehaviorSubject('');
  selectedHierarchyType = this.getSelectedHierarchyType.asObservable();

  private getSelectedKpiName = new BehaviorSubject('');
  selectedKpiName = this.getSelectedKpiName.asObservable();

  private getKpiRuleId = new BehaviorSubject('');
  selectedKpiRuleId = this.getKpiRuleId.asObservable();

  private signIn = new BehaviorSubject<boolean>(false);
  signin = this.signIn.asObservable();

  private isAssemblyLines = new BehaviorSubject(false);
  asseblyLinesFlag = this.isAssemblyLines.asObservable();

  private getAlertMenu = new BehaviorSubject(false);
  alertMenu = this.getAlertMenu.asObservable();

  private getDeviceStatus = new BehaviorSubject('');
  deviceStatus = this.getDeviceStatus.asObservable();

  private getPlantChildEquipments = new BehaviorSubject('');
  plantChildEquipments = this.getPlantChildEquipments.asObservable();

  private getEquipmentsForProdVsTarget = new BehaviorSubject('');
  prodVsTargetEquipments = this.getEquipmentsForProdVsTarget.asObservable();

  private getAlarmAndAlert = new BehaviorSubject({alarm:'',alert:'',ackAlarm:'',ackAlert:''});
  alarmAndAlert = this.getAlarmAndAlert.asObservable();

  private getAckAlarmAndAlert = new BehaviorSubject({ackAlarm:'',ackAlert:''});
  ackAlarmAndAlert = this.getAckAlarmAndAlert.asObservable();

  private getHAlarmAndAlert = new BehaviorSubject({hAlarm:'',hAlert:''});
  hAlarmAndAlert = this.getHAlarmAndAlert.asObservable();

  // private getStatusAndLastReceived = new BehaviorSubject({deviceStatus:'',lastReceived:''});
  // statusAndLastReceived = this.getStatusAndLastReceived.asObservable();
  constructor() { }
  setAlarmAndAlert(data)
  {
    this.getAlarmAndAlert.next(data);
  }
  setAckAlarmAndAlert(data)
  {
    this.getAckAlarmAndAlert.next(data);
  }
  setHAlarmAndAlert(data)
  {
    this.getHAlarmAndAlert.next(data);
  }
  setDeviceStatus(status:any)
  {
    this.getDeviceStatus.next(status);
  }
  setAlertMenuScreen(data)
  {
    this.getAlertMenu.next(data);
  }
  getDirectDashboard(data)
  {
    this.directDashboard.next(data);
  }

  userSignIn(data) {
    this.signIn.next(data);
  }

  changeMessage(wellid: string) {
    this.getCurrentWell.next(wellid)
  }

  setConnect(connect: boolean) {
    this.getConnect.next(connect)
  }

  setEquipmentId(equipmentId: string) {
    this.getEquipmentId.next(equipmentId)
  }

  SetCurrenttabId(tabId: string) {
    this.getCurrentTabid.next(tabId)
  }

  SetSelectedRoute(route: string) {
    this.getCurrentRoute.next(route);
  }
  setCurrentPlantEquipments(equipmentId: any) {
    this.getPlantEquipments.next(equipmentId)
  }

  setselectedPlantId(plantId: string) {
    this.getSelectedPlantId.next(plantId)
  }
  setNotificationInfo(info: any) {
    this.getNotificationInfo.next(info)
  }
  setNomalizedTagId(tagId: string) {
    this.getNormalizedTagId.next(tagId)
  }
  setSelectedKpiName(kpiName: string) {
    this.getSelectedKpiName.next(kpiName)
  }
  setSelectedHierarchyType(type: string) {
    this.getSelectedHierarchyType.next(type)
  }
  changeKpiRuleId(id) {
    this.getKpiRuleId.next(id);
  }
  setFullBreadcrumb(path) {
    this.getBreadcrumb.next(path);
  }
  setAsseblyLinesFlag(falg:boolean) {
    this.isAssemblyLines.next(falg);
  }
  setCurrentPlantChildEquipments(equipmentId: any) {
    this.getPlantChildEquipments.next(equipmentId)
  }
  setProdVsTargetEquipments(equipmentId: any) {
    this.getEquipmentsForProdVsTarget.next(equipmentId)
  }
  setisAGMMarker(data){
    this.isAGMMarker = data;
  }
  getisAGMEMarker(){
    return this.isAGMMarker;
  }
  latLng: any;
  plantsmarkersData: any;
  setLatLng(latLng) {
    this.latLng = latLng;
  }
  getLatLng() {
    return this.latLng;
  }

  setPlantsmarkersData(plantsmarkersData) {
    this.plantsmarkersData = plantsmarkersData;
  }
  getPlantsmarkersData() {
    return this.plantsmarkersData;
  }

  setNotificationType(notificationType) {
    this.notificationType = notificationType;
  }
  getNotificationType() {
    return this.notificationType;
  }

  // setStatusAndLastReceived(statusAndDate)
  // {
  //   this.getStatusAndLastReceived.next(statusAndDate);
  // }
}

