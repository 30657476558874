import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { LoginuserdataService } from '../services/userdata/loginuserdata.service';

@Injectable()
export class AuthGuard implements CanActivate {
    states: boolean = false;
    constructor(private router: Router,private uData:LoginuserdataService) { }
   
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.states = false;
        let routeName = state.url.substring(state.url.lastIndexOf('/') + 1);

        let userDetails: any =this.uData.getNewData();// JSON.parse(localStorage.getItem('user'));
        if (userDetails != null) {
            userDetails.lstRolename.forEach(element => {
                if (element.features.filter(x => x.featureRoute == routeName).length > 0) {
                    this.states = true;
                }
            });
        }
        //added by sravanthi to work out log out
        else 
        {
            let compName=this.router.url.split('/');
           // let selComponentName=compName[1];
            if(compName[1]=="login")
            {
                this.router.navigate(['/login']);
                return false;
            }
        }
        if (this.states == false) {
            this.router.navigate(['/not-found']);
            return false;
        } else {
            return true;
        }
    }
}

