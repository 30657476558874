// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientId: '{f145d324-ff57-40d9-9b00-0149cb1030a0}',
  authority: 'https://login.microsoftonline.com/{ebd95286-551e-4b34-9fef-60b74293d1f9}/',
  redirectUrl: window.location.origin,
  tenantId: '{ebd95286-551e-4b34-9fef-60b74293d1f9}',

  defaultApiUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/envapi/",
  envId:3,  //1 - Dev, 2 - Prod , 3 - IpReadiness

  discreteUrl:"https://pharma.centralus.cloudapp.azure.com/api/Asset/",
  baseUrl:"https://ogapi.azurewebsites.net/",
  apexi4api:"https://apexi4api.azurewebsites.net/",
  authapi:"https://apexauthapi.azurewebsites.net/api/",
  auditApi: "https://apexauditapi.azurewebsites.net/api/",
  sbdapi:"http://13.71.86.103:8080/cmooeefetchservices/api/sbd_v1/getLineMetrics/",
  graphapi:"https://apexgraphapi1.azurewebsites.net/api/",
  awsapi:
  "https://46g2r64xb5.execute-api.us-east-1.amazonaws.com/aws/",
  vibration: "https://apex-vibrationalanalysis.azurewebsites.net/",
  //weatherApiURL:"https://api.weatherapi.com/v1/forecast.json?key=4740f807b617471f860143028221903",
  //"http://ec2-3-231-251-60.compute-1.amazonaws.com:8080/aws/"

  // baseUrl:"https://ogapi.azurewebsites.net/",
  // apexi4api:"https://apexi4api.azurewebsites.net/",
  // authapi:"http://localhost:49843/api"
  apexv2GetParametersapi:"http://apexv2.azurewebsites.net/api/v2/params/",
  apexv2GetRunTimeDataapi:"http://apexv2.azurewebsites.net/api/v2/display",
  apexv2GetCSTRRunTimeDataapi:"http://apexv2.azurewebsites.net/api/v2/cstr/getCSTRRunData/",
  apexv2GetCSTRParamDataapi:"http://apexv2.azurewebsites.net/api/v2/display/getcstrParams",
  apexv2GetPumpRD:"http://apexv2.azurewebsites.net/api/v2/pump/pumpRD",
  apexv2SaveActionDetails:"http://apexv2.azurewebsites.net/api/v2/actions/",
  apexv2SaveActionFlag:"http://apexv2.azurewebsites.net/api/v2/pump/false",
  //apexhierarchy:"http://52.228.231.193/api/Asset/GetCompleteHierarchy" ,
  //apexcstrnewParamDataapi: "http://52.228.231.193/api/Asset/",

  //--------------Azure Edge API end points ---------------------//
  /*discreteUrlNew:"https://pharmaapidev.eastus.cloudapp.azure.com/",
  assetURL: "https://pharmaapidev.eastus.cloudapp.azure.com/assetapi/",
  telmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/telemetryapi/",
  rulesUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/ruleapi/",
  kpiURL: "https://pharmaapidev.eastus.cloudapp.azure.com/kpibuilderapi/API/KPI/Upload",
  kpiBuildUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/kpiapi/",
  alertsUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/alertapi/"*/

  //--------------Azure Cloud API end points ---------------------//
  /* assetURL: "https://pharmaapidev.eastus.cloudapp.azure.com/assetcloudapi/",
   telmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/telemetrycloudapi/",
   rulesUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/rulecloudapi/",
   kpiURL: "https://pharmaapidev.eastus.cloudapp.azure.com/kpibuilderapi/API/KPI/Upload",
   kpiBuildUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/kpicloudapi/",
   alertsUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/alertcloudapi/",
   anamolyRange:"https://pharmaapidev.eastus.cloudapp.azure.com/anamolyserviceapi/",

   anomalytelmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/telemetryserviceazureanamolyapi/",
   ui_configURL:window.location.origin+"/assets/ui_config.json",
   // statusURL:window.location.origin+"/assets/status.json",
   statusURL:"https://pharmaapidev.eastus.cloudapp.azure.com/snapshotapi/EquipmentSnapshot/",
   configurationUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/configcloudapi/"*/

   //--------------Azure Dev end points ---------------------------//
   /*assetURL: "https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/assetapi/",
  telmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/telemetryapi/",
  rulesUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/ruleapi/",
  kpiURL: "https://pharmaapidev.eastus.cloudapp.azure.com/kpibuilderapi/API/KPI/Upload",
  kpiBuildUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/kpiapi/",
  alertsUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/alertapi/",
  //anamolyRange:"https://pharmaapidev.eastus.cloudapp.azure.com/anamolyserviceapi/",
  anamolyRange:"https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/anamolyserviceapi/",
  anomalytelmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/telemetryserviceazureanamolyapi/",

  ui_configURL:window.location.origin+"/assets/ui_config.json",
  // statusURL:window.location.origin+"/assets/status.json",
  // tileAlert:"https://pharmaapidev.eastus.cloudapp.azure.com/alertcloudapi/",
  //statusURL:"https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/snapshotapi/EquipmentSnapshot/",
  statusURL:"https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/snapshotapi/",
  configurationUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/configapi/",
  grafanakpiUrl:"http://18.211.113.254/d/-pVr9Wwnz/landing-page-with-loop?orgId=1&var-KPI=All&var-EntityId="*/

  //--------------Azure Cloud API end points ---------------------//
  // assetURL: "https://pharmaapidev.eastus.cloudapp.azure.com/assetcloudapi/",
  // telmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/telemetrycloudapi/",
  // rulesUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/rulecloudapi/",
  // kpiURL: "https://pharmaapidev.eastus.cloudapp.azure.com/kpibuilderapi/API/KPI/Upload",
  // kpiBuildUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/kpicloudapi/",
  // alertsUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/alertcloudapi/",
  // anamolyRange:"https://pharmaapidev.eastus.cloudapp.azure.com/anamolyserviceapi/",

  // anomalytelmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/telemetryserviceazureanamolyapi/",
  // ui_configURL:window.location.origin+"/assets/ui_config.json",
  // // statusURL:window.location.origin+"/assets/status.json",
  // statusURL:"https://pharmaapidev.eastus.cloudapp.azure.com/snapshotcloudapi/Entities/",
  // configurationUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/configcloudapi/",
  // configurationInputUrl: "https://lookupapi-n3sszbrn5a-uc.a.run.app/api/",
  // grafanakpiUrl:"http://18.211.113.254/d/-pVr9Wwnz/landing-page-with-loop?orgId=1&var-KPI=All&var-EntityId="




  assetURL: "",
  telmetryURL:"",
  rulesUrl:"",
  kpiURL: "",
  kpiBuildUrl:"",
  alertsUrl: "",
  anamolyRange:"",

  anomalytelmetryURL:"",
  ui_configURL:window.location.origin+"/assets/ui_config.json",
  // statusURL:window.location.origin+"/assets/status.json",
  statusURL:"",
  configurationUrl: "",
  configurationInputUrl: "",
  grafanakpiUrl:"",
  adminURL:"",
  weatherApiURL:"https://api.weatherapi.com/v1/forecast.json?key=4740f807b617471f860143028221903",
  cityListApiUrl :'https://apexwaterutilitesuudemo.azurewebsites.net/citylist',
  erroreMessages :'https://apexwaterutilitesuudemo.azurewebsites.net/message',
  unitedUtilitiesApi :'https://pharmaapidev.eastus.cloudapp.azure.com/ctsapexcloud/UUStaticapi/UnitedUtilities/',
  maintenanceApi :'https://apex-maintenance-service.azurewebsites.net/api/',
  grafanaUrl : "https://batchtracking.grafana.ctsapex2.com/",//"https://52.22.89.81:3000/"
  //---------------Azure edge API end points ----------------------//
  /*assetURL: "http://20.127.145.85:81/assetapi/",
   telmetryURL:"http://20.127.145.85/",
   rulesUrl:"http://20.127.145.85:83/",
   kpiURL: "https://pharmaapidev.eastus.cloudapp.azure.com/kpibuilderapi/API/KPI/Upload",
   kpiBuildUrl:"http://20.127.145.85:84/",
   alertsUrl: "http://20.127.145.85:85/",
   anamolyRange:"https://pharmaapidev.eastus.cloudapp.azure.com/anamolyserviceapi/",

   anomalytelmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/telemetryserviceazureanamolyapi/",

   ui_configURL:window.location.origin+"/assets/ui_config.json",
   statusURL:"http://20.127.145.85:5592/Entities/",
   configurationUrl: "http://20.127.145.85:82/",
   configurationInputUrl: "https://lookupapi-n3sszbrn5a-uc.a.run.app/api/",
   grafanakpiUrl:"http://18.211.113.254/d/-pVr9Wwnz/landing-page-with-loop?orgId=1&var-KPI=All&var-EntityId="*/

   //--------------Azure Cloud CTS Apex API end points ---------------------//
  /*assetURL: "https://pharmaapidev.eastus.cloudapp.azure.com/ctsapexcloud/assetapi/",
  telmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/ctsapexcloud/telemetryapi/",
  rulesUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/ctsapexcloud/ruleapi/",
  kpiURL: "https://pharmaapidev.eastus.cloudapp.azure.com/kpibuilderapi/API/KPI/Upload",
  kpiBuildUrl:"https://pharmaapidev.eastus.cloudapp.azure.com/ctsapexcloud/kpiapi/",
  alertsUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/ctsapexcloud/alertapi/",
  anamolyRange:"https://pharmaapidev.eastus.cloudapp.azure.com/ctsapexcloud/anamolyserviceapi/",

  anomalytelmetryURL:"https://pharmaapidev.eastus.cloudapp.azure.com/telemetryserviceazureanamolyapi/",
  ui_configURL:window.location.origin+"/assets/ui_config.json",
  // statusURL:window.location.origin+"/assets/status.json",
  statusURL:"https://pharmaapidev.eastus.cloudapp.azure.com/ctsapexcloud/snapshotapi/Entities/",
  configurationUrl: "https://pharmaapidev.eastus.cloudapp.azure.com/ctsapexcloud/configapi/",
  configurationInputUrl: "https://lookupapi-n3sszbrn5a-uc.a.run.app/api/",
  grafanakpiUrl:"http://18.211.113.254/d/-pVr9Wwnz/landing-page-with-loop?orgId=1&var-KPI=All&var-EntityId="*/
   //--------------Normal AWS API end points ---------------------//
  /*assetURL: "https://api.dev.ctsapex2.com/assetapi/",//"http://a795ed443904c4479b5ed14b15e50e48-16665999.us-east-1.elb.amazonaws.com/",//"http://a917c0f1911374df68162d441297bec5-523606745.us-east-1.elb.amazonaws.com/",
  telmetryURL:"https://api.dev.ctsapex2.com/telematicapi/",//"http://ad4560b7825aa420488a81fcaf7d4ce7-1796537839.us-east-1.elb.amazonaws.com/",//"http://a52607d826d634b5e81ae8b7395baddd-1925029428.us-east-1.elb.amazonaws.com/",
  rulesUrl:"https://api.dev.ctsapex2.com/ruleapi/",
  kpiURL: "http://a5634db38a3d842c9b6b2a9accc71198-592326821.us-east-1.elb.amazonaws.com/API/KPI/UploadToS3",
  kpiBuildUrl:"https://api.dev.ctsapex2.com/kpiapi/",//"https://pharmaapidev.eastus.cloudapp.azure.com/kpiapi/"
  alertsUrl: "https://api.dev.ctsapex2.com/alertapi/"*/


    //--------------AWS Cloud API end points ---------------------//
  /*assetURL: "https://api.dev.ctsapex2.com/cloud/assetapi/",
  telmetryURL:"https://api.dev.ctsapex2.com/cloud/telematicapi/",
  rulesUrl:"https://api.dev.ctsapex2.com/cloud/ruleapi/",
  kpiURL: "https://api.dev.ctsapex2.com/cloud/kpibuilderapi/API/KPI/UploadToS3",
  kpiBuildUrl:"https://api.dev.ctsapex2.com/cloud/kpiapi/",
  alertsUrl: "https://api.dev.ctsapex2.com/cloud/alertapi/"*/

  //--------------Green Grass API end points ---------------------//
/*assetURL: "https://api.dev.ctsapex2.com/greengrass/assetapi/",
  telmetryURL:"https://api.dev.ctsapex2.com/greengrass/telematicapi/",
  rulesUrl:"https://api.dev.ctsapex2.com/greengrass/ruleapi/",
  kpiURL: "https://api.dev.ctsapex2.com/greengrass/kpibuilderapi/API/KPI/UploadToS3",
  kpiBuildUrl:"https://api.dev.ctsapex2.com/greengrass/kpiapi/",
  alertsUrl: "https://api.dev.ctsapex2.com/greengrass/alertapi/"*/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
