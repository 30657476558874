import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginuserdataService {

  private getCurrentUser = new BehaviorSubject('');
  currentUser = this.getCurrentUser.asObservable();

  private newData = {
    fullname: "",
    id: "",
    lstRolename: [],
    role: "",
    token: "",
    username: ""
  }
  constructor() { }

  setSelectedUser(userInfo:any)
  {
    this.getCurrentUser.next(userInfo);
  }

  setNewData(data)
  {
    this.newData = data;
  }
  getNewData()
  {
    return this.newData;
  }
}
