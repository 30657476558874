import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { HttpConfigInterceptor } from './shared/interceptor/http.interceptor';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import {MatBadgeModule} from '@angular/material';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { SafeHtmlPipe } from './safe-html.pipe';
import { MsalModule, MsalService }
 from '@azure/msal-angular';
import { AppInitService } from './app-init.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimulatorComponent } from './simulator/simulator.component';


//import { OperationComponent } from './operation/operation.component';

export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => { 
    return appInitService.Init();
  }
}
@NgModule({
  declarations: [AppComponent, SafeHtmlPipe, SimulatorComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MsalModule,
    MatBadgeModule,
    FormsModule,ChartsModule, ProgressbarModule.forRoot(),
    NgbModule //,
   // ArchwizardModule
  ],
  exports: [SafeHtmlPipe],
  providers: [AuthGuard, DatePipe,
    // {
    //   provide: MSAL_CONFIG,
    //   useFactory: MSALConfigFactory
    // },
    // {
    //   provide: MSAL_CONFIG_ANGULAR,
    //   useFactory: MSALAngularConfigFactory
    // },
    MsalService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },{provide : LocationStrategy , useClass: HashLocationStrategy},
    AppInitService,
    { provide: APP_INITIALIZER,useFactory: initializeApp1, deps: [AppInitService], multi: true} ],

  bootstrap: [AppComponent]
})
export class AppModule { }
