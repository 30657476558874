import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from '@angular/router';

@Injectable()

export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(localStorage.getItem('azureToken')) {
            request = request.clone({
                setHeaders: {
                //   'Content-Type' : 'application/json; charset=utf-8',
                //   'Accept'       : 'application/json',
                'Authorization': `${localStorage.getItem('azureToken')}`, //Bearer
                },
            });
        }
          //user-mail 
        return next.handle(request).pipe(tap(event => {
            if (event instanceof HttpResponse) {
                //const elapsed = Date.now() - started;
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this.router.navigate(['/login']);
                }
            }
        }
        ));
    }
}