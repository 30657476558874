import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string, args?: any): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }

}
