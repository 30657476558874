import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private getCurrentDashboard = new BehaviorSubject('dashboard');
  currentDashboard = this.getCurrentDashboard.asObservable();

  private getCurrentUserRole = new BehaviorSubject('ADMIN');
   currentUserRole = this.getCurrentUserRole.asObservable();

   private getCurrentScreenName = new BehaviorSubject('dashboard');
   currentScreenName = this.getCurrentScreenName.asObservable();

   private setHeader = new BehaviorSubject('');
   dheader = this.setHeader.asObservable();
  constructor() { }
  changeHeader(headerName) {
    this.setHeader.next(headerName);
    
  }
  changeDashboard(dashName: string) {
    this.getCurrentDashboard.next(dashName);
    
  }
  setCurrentUserRole(roleName: string) {
    this.getCurrentUserRole.next(roleName);
    
   }

   screenName(scrName:string) {
    this.getCurrentScreenName.next(scrName);
   }
 
}
