import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MsalAngularConfiguration, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { Configuration } from 'msal';
if (environment.production) {
  enableProdMode();
}
function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      validateAuthority: true,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: 'https://login.microsoftonline.com/' + environment.tenantId + '/oauth2/v2.0/logout',
      navigateToLoginRequestUrl: true
    },
    cache: {
      storeAuthStateInCookie: false,
    }
  };
}
function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: false
  };
}
platformBrowserDynamic([
  {
    provide: MSAL_CONFIG,
    useFactory: MSALConfigFactory,
    deps:[]
  },
  {
    provide: MSAL_CONFIG_ANGULAR,
    useFactory: MSALAngularConfigFactory,
    deps:[]
  },
]).bootstrapModule(AppModule)
  .catch(err => console.error(err));
