import { FormGroup, FormControl, Validators } from '@angular/forms';

export class WorkOrderDetailsModel {
    workOrderNumber?: string;
    productionLine?: string;
    processNumber?: string;
    status?: string;
    startDate?: string;
    endDate?: string;
    quantity?: string;
    batchNumber?: string;
    productProcessType?: string;
    userName?: string;
    importStatus?: string;
    fillingType?: number;
}
export class BoilingDetailsModel {
    workOrderNumber?: string;
    processNumber?: string;
    boilerName?: string;
    boilingDetail?: boilingDetail[];
}
export class boilingDetail {
    brix?: string;
    pH?: string;
    temp?: string;
    comment?: string;
    taste?: string;
    colour?: string;
    visual?: string;
    texture?: string;
    cvit?: string;
    subBatch?: string;
    GK_UK?: string;
    boilingDate?: string;
    boilerName?: string;
    userName?: string;
}

export class SaveBoilingDetailsModel {
    id?: string;
    workOrderNumber?: string;
    processNumber?: string;
    boilerName?: string;
    boilingDetail?: boilingDetail[];
}
export class WeighingDetailsModel {
    workOrderNumber: string;
    processNumber: string;
    processDetail: processDetail[] = new Array();
}

export class processDetail {
    denomination: string;//changed from denomination
    subBatch: string;
    spare: string;
    unit: string;
    quantity: string;
    actulQuantity: string;
    weighingDate: string;
    userName: string;

    static asFormGroup(album: processDetail): FormGroup {
        const fg = new FormGroup({
            denomination: new FormControl(album.denomination),
            subBatch: new FormControl(album.subBatch),
            unit: new FormControl(album.unit),
            quantity: new FormControl(album.quantity),
            actulQuantity: new FormControl(album.quantity, Validators.required),
        });
        return fg;
    }
}

export class processHistory {
    subBatch: string;
    weighingDate: string;
    userName: string;
}

export class User {
    userId: number;
    userName: string;
    password: string;
    userRole: string;
    message: string;
}

export class FillingInspection {
    fillingDate: string;
    expirationDate: String;
    lineInspection?: string;
    machineSafety?: string;
    liftingTools?: string;
    lookingGlass?: string;
    protections?: string;
    frontLabel?: string;
    backLabel?: string;
    trayMarking?: string;
    eanReadablility?: string;
    labelsRollChange?: string;
    moNrBottle?: string;
    batchNrCaps?: string;
    ph?: string;
    userName?: string;

    processWorkOrderNo?: string;
    batchNo?: string;

}

export class FillingRefillingInspection {
    fillingDate: string;
    expirationDate: string;
    lineInspection?: string;
    dailySafetyInspection?: string;
    dailyInspectionGlass?: string;
    brix?: string;
    look?: string;
    temp?: string;
    lotNrFilm?: string;
    ph?: string;
    userName?: string;

    processWorkOrderNo?: string;
    batchNo?: string;
}

export class FillingDetailsModel {
    workOrderNumber: string;
    processNumber: string;
    fillingInspection: FillingInspection[] = new Array();
}

export class FillingRefillingDetailsModel {
    workOrderNumber: string;
    processNumber: string;
    refillingInspection: FillingRefillingInspection[] = new Array();
}


export class ResponseImportWorkOrder {
    newWorkOrder?: string;
    existWorkOrder?: string;
}


export class FinalInspectionModel {
    workOrderNumber?: string;
    processNumber?: string;
    brixMin?: string;
    brixMax?: string;
    pHMin?: string;
    pHMax?: string;
    tapMin?: string;
    tapMax?: string;
    finalInspectionDate?: string;
    brix?: string;
    pH?: string;
    taste?: string;
    colour?: string;
    visual?: string;
    texture?: string;
    temp?: string;
    borkenGlass?: string;
    bakeTest?: string;
    avvrValue?: string;
    vacuum?: string;
    cppVacuum?: string;
    headSpace?: string;
    expDate?: string;
    comment?: string;
    okNotOk?: string;
    userName?: string;
}


export class IoTdeviceModel {
    brix?: string;
    pH?: string;
    temp?: string;    
    time?:string;
}


export class recipeDetail {
    processNumber?: string;
    denomination?: string;
    spare?: string;
    unit?: string;
    quantity?: string;
}

export class importWorkOrder {
    ImportStatus?: string
    ProductionLine?: string
    WorkOrderNumber?: string
    PartNumber?: string
    Status?: string
    unknown?: string
    StartDate?: string
    EndDate?: string
    Quantity?: string
    Batch?: string
}

export class popupModalData{
    column1?:string;
    column2?:string;
}


export class chartmodal
{
  data:string[];
  label:string;
}