import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  
  //envId = 1; // 1 - Dev, 2 - Prod , 3 - IpReadiness
  //defaultURL="https://pharmaapidev.eastus.cloudapp.azure.com/apexclouddev/envapi/UIConfigurations/GetEnvCalls?id=1";
  defaultURL=`${environment.defaultApiUrl}UIConfigurations/GetEnvCalls?id=${environment.envId}`;
  constructor(private http:HttpClient) { }

  Init() {
    return new Promise<void>((resolve, reject) => {
        ////do your initialisation stuff here  
        this.http.get(this.defaultURL).subscribe((apiList:any)=>{
          environment.assetURL = apiList.find(item=>item.api_Name == 'assetURL').url;
          environment.telmetryURL = apiList.find(item=>item.api_Name == 'telmetryURL').url;
          environment.rulesUrl = apiList.find(item=>item.api_Name == 'rulesUrl').url;
          environment.kpiURL = apiList.find(item=>item.api_Name == 'kpiURL').url;
          environment.kpiBuildUrl = apiList.find(item=>item.api_Name == 'kpiBuildUrl').url;
          environment.alertsUrl = apiList.find(item=>item.api_Name == 'alertsUrl').url;
          environment.anamolyRange = apiList.find(item=>item.api_Name == 'anamolyRange').url;
          environment.anomalytelmetryURL = apiList.find(item=>item.api_Name == 'anomalytelmetryURL').url;
          environment.statusURL = apiList.find(item=>item.api_Name == 'statusURL').url;
          environment.configurationUrl = apiList.find(item=>item.api_Name == 'configurationUrl').url;
          environment.grafanakpiUrl = apiList.find(item=>item.api_Name == 'grafanakpiUrl').url;
          environment.configurationInputUrl = apiList.find(item=>item.api_Name == 'configurationInputUrl').url;
          environment.adminURL = apiList.find(item=>item.api_Name == 'adminURL').url;      
          resolve();
        })


    });
}
}
