import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': '*/*'
  })
}

@Injectable({
  providedIn: 'root'
})
export class PlantserviceService {

  roleId = localStorage.getItem('roleId')
  constructor(private http: HttpClient) { }
  public tileAlert(id,limit=5)
  {
    return this.http.get(`${environment.alertsUrl}Alert/IsHavingAlerts?limit=${limit}&AssetID=${id}`);
  }
  public tileAlertDetails(id,tid)
  {
    return this.http.get(`${environment.alertsUrl}Alert/IsHavingAlerts?AssetID=${id}&ParameterID=${tid}`);
  }
  public tileAlertDetailsNew(id,tid,limit=10)
  {
    return this.http.get(`${environment.alertsUrl}Alert/GetEntityParameterAlerts?equipmentId=${id}&parameterId=${tid}&limit=${limit}`);
  }
  public getStatus(list,firstTimeCall)
  {
    //return this.http.get(`${environment.statusURL}GetMultiEquipmentSnapshot/?equipmentIds=${list}`);
    //return this.http.get(`${environment.statusURL}Status?entityIds=${list}`);
    return this.http.get(`${environment.statusURL}CloudConnectedDevices?firstTimeCall=${firstTimeCall}`);
  }
  //url='https://pharma.centralus.cloudapp.azure.com/api/Asset/'
  public getUIConfigurationData(entityType='All',dataType='All')
  {
    return this.http.get(`${environment.configurationUrl}UIConfigurations?entityTypes=${entityType}&dataTypes=${dataType}`);
  }
  public getPumpingStationUIConfigurationData()
  {
    return this.http.get(`${environment.ui_configURL}`);
  }
  public getHierarchy()
  {
    this.roleId = localStorage.getItem('roleId')
   return this.http.get(`${environment.assetURL}Asset/GetCompleteHierarchy?roleid=${this.roleId}`);
  }
//discreteUrlNew
  public getParameterInfo(selectedPlantId)
 {
  return this.http.get(`${environment.assetURL}Asset/GetParameterInfo?equipmentID=`+ selectedPlantId);
 }

 public getParamLatestInfo(selectedPlantId,parameterName)
 {
  return this.http.get(`${environment.telmetryURL}Telemetry/GetLatestParamValue?equipmentID=`+ selectedPlantId + `&parameterId=`+parameterName);
 }

 public getTrends(selectedPlantId,paramId,startDateTime,endDateTime)
 {
  return this.http.get(`${environment.telmetryURL}Telemetry/GetTrends?equipmentID=`+ selectedPlantId + `&parameterIds=`+paramId + `&startDateTime=` +startDateTime +`&endDateTime=`+ endDateTime);
  //return this.http.get('https://pharmaapi.eastus.cloudapp.azure.com/telemetryapi/Telemetry/GetTrends?equipmentID=CFBBLH-01&parameterIds=TT01,TT02&startDateTime=09-17-2020 03:50:00&endDateTime=09-17-2020 04:54:57');
 }
   
 public getPlants(typeId)
 {
  return this.http.get(`${environment.assetURL}Asset/GetLocation?entityType=${typeId}`);
 }  
 public getKpiGraphData(kpiName,entityId,startDate,endDate) {
  //return this.http.get(`${environment.kpiBuildUrl}kpi/getdata?Kpiname=${kpiName}&Assetid=${assetId}&StartDate=${startDate}&enddate=${endDate}`);
  return this.http.get(`${environment.kpiBuildUrl}kpi/getdata?Kpiname=${kpiName}&Entityid=${entityId}&StartDate=${startDate}&enddate=${endDate}`);
 }

 public getNormalizedParamLatestInfo(selectedPlantId,frequency=99) {
  //return this.http.get(`${environment.telmetryURL}Telemetry/GetLatestParamValueNorm?equipmentID=`+ selectedPlantId);
  return this.http.get(`${environment.telmetryURL}Telemetry/GetLatestParamValueAggr?frequency=${frequency}&entityid=`+ selectedPlantId);
 }

 public anomalygetNormalizedParamLatestInfo(selectedPlantId,freq) {
  //return this.http.get(`${environment.telmetryURL}Telemetry/GetLatestParamValueNorm?equipmentID=`+ selectedPlantId);
  return this.http.get(`${environment.anomalytelmetryURL}Telemetry/GetLatestParamValueAggr?frequency=${freq}&entityid=`+ selectedPlantId);
 }
 public getNormalizedTrends(selectedPlantId,paramId,startDateTime,endDateTime,frequency) {
  //return this.http.get(`${environment.telmetryURL}Telemetry/GetTrendsNorm?equipmentID=`+ selectedPlantId + `&parameterIds=`+paramId + `&startDateTime=` +startDateTime +`&endDateTime=`+ endDateTime); 
  return this.http.get(`${environment.telmetryURL}Telemetry/GetTrendsAggr?freq=${frequency}&entityId=`+ selectedPlantId + `&parameterIds=`+paramId + `&starttime=` +startDateTime +`&endtime=`+ endDateTime); 
 }
 public getKpiEquipment(kpiName, EquipmentIds) {
  return this.http.get(`${environment.kpiBuildUrl}kpi/GetKPIByEquipmentId?KPIName=${kpiName}&EquipmentIds=${EquipmentIds}`);
 }
 public getLatestKpiValue(kpiName,entityId) {
  return this.http.get(`${environment.kpiBuildUrl}kpi/GetLatestKpiValue?kpiname=${kpiName}&entityid=${entityId}`);
 }
 public getKpiThreshold() {
  return this.http.get(`${environment.kpiBuildUrl}kpi/GetKPIThreshold`);
 }
 public getLatestKpiStatus(entityId,source) {
  return this.http.get(`${environment.kpiBuildUrl}kpi/getlatestkpistatus?entityid=${entityId}&Source=${source}`);
  // https://pharmaapidev.eastus.cloudapp.azure.com/kpiapi/kpi/getlatestkpistatus?entityid=CFBBK-01&Source=Speed,Vibration
 }
 public getShiftTimings(entityId) {
  return this.http.get(`${environment.assetURL}Asset/GetCurrentShift?EntityId=${entityId}`);
 }
 public getKpiChildren(kpiName) {
  //return this.http.get(`${environment.kpiBuildUrl}kpi/getdata?Kpiname=${kpiName}&Assetid=${assetId}&StartDate=${startDate}&enddate=${endDate}`);
  return this.http.get(`${environment.kpiBuildUrl}kpi/GetKPIChildren?kpi=${kpiName}`);
 }
 public getKpiHistoricGraphData(frequency,entityId,kpiName,startDate,endDate) {
  //return this.http.get(`${environment.kpiBuildUrl}kpi/getdata?Kpiname=${kpiName}&Assetid=${assetId}&StartDate=${startDate}&enddate=${endDate}`);
  return this.http.get(`${environment.kpiBuildUrl}kpi/GetTrendsAggr?freq=${frequency}&entityId=${entityId}&kpiname=${kpiName}&starttime=${startDate}&endtime=${endDate}`);
 }

 public getLatestIntputRangeAnamoly(frequency,paramId,startDateTime,endDateTime,entityId) {
  return this.http.get(`${environment.anamolyRange}Telemetry/GetLastRangeInputAnamoly?freq=${frequency}&entityId=${entityId}&parameterIds=`+paramId + `&starttime=` +startDateTime +`&endtime=`+ endDateTime); 
 }
 public getLatestOutputRangeAnamoly(frequency,paramId,startDateTime,endDateTime,entityId) {
  return this.http.get(`${environment.anamolyRange}Telemetry/GetLastRangeOutputAnamoly?freq=${frequency}&entityId=${entityId}&parameterIds=`+paramId + `&starttime=` +startDateTime +`&endtime=`+ endDateTime); 
 }
 public getOutputCollectiveAnamoly(frequency,paramId,startDateTime,endDateTime) {
  return this.http.get(`${environment.anamolyRange}Telemetry/GetOutputCollectiveAnamoly?freq=${frequency}&starttime=` +startDateTime +`&endtime=`+ endDateTime); 
 }
 getLastTwelveMonths() {
  var monthName = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
  var monthsArr = [];
  var d = new Date();
  d.setDate(1);
  for (let i=0; i<=11; i++) {
      //months.push(monthName[d.getMonth()] + ' ' + d.getFullYear());
      let mnt = {
        "name":monthName[d.getMonth()] + ' ' + d.getFullYear(),
        "value":monthName[d.getMonth()] + '-' + d.getFullYear(),
        "monthYear":d.getMonth+'-'+d.getFullYear()
      }
      monthsArr.push(mnt)
      d.setMonth(d.getMonth() - 1);
  }
  return monthsArr;
  }
  getMachineLevelParetoDowntime(entityId,startDateTime,endDateTime) {
    return this.http.get<any[]>(`${environment.assetURL}Asset/GetMLEntityDowntimeGraph?entityId=${entityId}&starttime=` +startDateTime +`&endtime=`+ endDateTime);
  }
  getPlantLevelDowntime(entityId,month,) {
    return this.http.get<any[]>(`${environment.assetURL}Asset/GetDownTime?EntityIds=${entityId}&Month=` +month);
  }
  getMachineStatusData(entityId,startDateTime,endDateTime) {
    return this.http.get<any[]>(`${environment.assetURL}Asset/GetEntityDTMachineStatus?entityID=${entityId}&StartDate=` +startDateTime +`&EndDate=`+ endDateTime);
  }
  getEntityDowntime(entityId,startDate,endDate) {
    return this.http.get<any[]>(`${environment.assetURL}Asset/GetEntityDowntime?entityId=${entityId}&startDate=${startDate}&endDate=${endDate}`);
  }
  getDowntimeReasons() {
    return this.http.get<any[]>(`${environment.assetURL}Asset/GetDowntimeReasoncode`);
  }
  getDowntimeCategory() {
    return this.http.get<any[]>(`${environment.assetURL}Asset/GetDowntimeCategory`);
  }
  updateResonCodeToEntity(json) {
    return this.http.put(`${environment.assetURL}/Asset/UpdateEntityDowntime`,json)
  }
  getKpiMetricsData(kpiName,entityId,month) {
    return this.http.get<any[]>(`${environment.kpiBuildUrl}kpi/PlantLevelKPIDashboard?KPIName=${kpiName}&AssetId=${entityId}&Date=${month}`);
  }
  getProdVsTargetData(entityId,month,week,viewType,source) {
    if(source === 'plant') {
      if(viewType === 'M') {
        return this.http.get<any[]>(`${environment.kpiBuildUrl}kpi/GetTargetProdRedis?AssetId=${entityId}&Month=${month}&PropertyID=TPC&View=${viewType}`);
      } else {
        return this.http.get<any[]>(`${environment.kpiBuildUrl}kpi/GetTargetProdRedis?AssetId=${entityId}&Month=${month}&PropertyID=TPC&Week=${week}&View=${viewType}`);
      }     
    } else {
      //return this.http.get<any[]>(`${environment.kpiBuildUrl}kpi/GetTargetProd?AssetId=${entityId}&Month=${month}&PropertyID=TPC&View=${viewType}`);
      return this.http.get<any[]>(`${environment.kpiBuildUrl}kpi/GetEquipmentTargetProdRedis?AssetId=${entityId}&Month=${month}&PropertyID=TPC`);
    }   
  }
  getTopSymphonyWidgetData(frequency,entityId) {
    return this.http.get(`${environment.telmetryURL}Telemetry/GetLatestParamValueAggrSymphony?frequency=${frequency}&entityId=${entityId}`); 
  }
  getSymphonyTrendsData(frequency,entityId,paramId,startDateTime,endDateTime) {
    if(paramId === 'Theoretical_Efficiency') {
      return this.http.get(`${environment.telmetryURL}Telemetry/GetTrendsEfficiency?freq=${frequency}&entityId=${entityId}&parameterIds=`+paramId + `&starttime=` +startDateTime +`&endtime=`+ endDateTime); 
    } else {
      return this.http.get(`${environment.telmetryURL}Telemetry/GetTrendsAnomalyScore?freq=${frequency}&entityId=${entityId}&parameterIds=`+paramId + `&starttime=` +startDateTime +`&endtime=`+ endDateTime); 
    }    
  }
  getEfficiencyiSymphonytData(frequency,entityId) {
    return this.http.get(`${environment.telmetryURL}Telemetry/GetLatestParamValueEffSymphony?frequency=${frequency}&entityId=${entityId}`); 
  }
  getEfficiencySymphonyTrendsData(frequency,entityId,paramId,startDateTime,endDateTime) {
    return this.http.get(`${environment.telmetryURL}Telemetry/GetTrendsEfficiency?freq=${frequency}&entityId=${entityId}&parameterIds=`+paramId + `&starttime=` +startDateTime +`&endtime=`+ endDateTime); 
  }
  getHealthScoreSymphonyData(kpiName,entityId,startDateTime,endDateTime) {
    return this.http.get(`${environment.kpiBuildUrl}kpi/GetData?KPIName=${kpiName}&EntityId=${entityId}&StartDate=` +startDateTime +`&EndDate=`+ endDateTime); 
  }
  getKpiPlantData() {
    return this.http.get(`${environment.kpiBuildUrl}kpi/GetPlantKPIData`); 
  }
  public getPlantKpiStatus(entityId,source) {
    return this.http.get(`${environment.kpiBuildUrl}kpi/GetPlantKPIStatus?KPIName=${source}&entityID=${entityId}`);
   }
   public getLeafEquipments(plantId) {
    return this.http.get<any[]>(`${environment.assetURL}Asset/GetLeafEquipment?entityName=`+ plantId);
   }
   public configurationInputTable(data) {
    return this.http.post(`${environment.configurationInputUrl}Data?`, data);
  }
  public getRole() {
    return this.http.get(`${environment.adminURL}Admin/GetRoleID`); 
  }
  public getPlantKpiData(plants,kpis) {
    // return this.http.get(`${environment.kpiBuildUrl}kpi/GetPlantKPIDataAzure?KPIName=${kpis}&EntityId=${plants}`)
    return this.http.get(`${environment.kpiBuildUrl}kpi/GetPlantKPIDataAzure?KPIName=OEE,ScrapRatio,ReworkRatio,Yield,FirstTimeYield,GoodTarget,ReworkHours&EntityId=${plants}`)
  }
  public getOpertationStatus(json) {
    return this.http.post(`${environment.telmetryURL}Telemetry/GetOperationStatus`,json); 
  }
  public getWeatherData(lat,lng) {
    return this.http.get(`${environment.weatherApiURL}&q=${lat},${lng}`); 
  }
  getCityList(): Observable<any> {
    return this.http.get<any>(`${environment.cityListApiUrl}`);
  }
  getWetherData(): Observable<any> {
    return this.http.get<any>(`${environment.unitedUtilitiesApi}GetWeatherData`);
  }
  // update forecast data - weather API to local db
  updateForecastData(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, { "forecast": data.fdata }, httpOptions)
  }

  // update plant health
  updatePlantHealth(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, { "plantHealth": data.fdata }, httpOptions)
  }

  // update reservoir level
  updateReservoirLevel(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, { "waterLevel": data.fdata }, httpOptions)
  }

  // modify precipitation forecast 
  updatePrepForecast(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, { "waterLevel": data.fdata }, httpOptions)
  }

  // update Raw Water Storage
  updateRawWaterStorage(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, { "rawWaterStorage": data.fdata }, httpOptions)
  }

  // update Treated Water Storage
  updateTreatedWaterStorage(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, { "treatedWaterStorage": data.fdata }, httpOptions)
  }

  // update Average Consumption Per Day
  updateAverageConsumptionPerDay(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, { "averageConsumptionPerDay": data.fdata }, httpOptions)
  }

  // update Pummp Status
  updatePumpStatus(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, data, httpOptions)
  }

  // update Valve tatus
  updateValveStatus(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, data, httpOptions)
  }
  // update Process Maintenance Date
  updateProcessMaintenanceDate(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    // console.log("Process Maintenance Date........", data);
    return this.http.patch<any[]>(url, { "scheduledProcessMaintainance": data.fdata }, httpOptions)
  }

  // update Next Rain Forecast Date
  updateNextRainForecastDate(data): Observable<any> {
    var url = `${environment.cityListApiUrl}/${data.id}`;
    return this.http.patch<any[]>(url, data, httpOptions)
  }

  public getAlarmsAlertsData(siteList)
  {
    return this.http.get(`${environment.alertsUrl}Alert/GetAlarmAlertCount?EquipmentID=${siteList}`);
  }
}
