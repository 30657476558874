import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { SimulatorComponent } from './simulator/simulator.component';

// AD-AUTHENTICATION
const routes: Routes = [
  {
    // path: '',
    // component: AppComponent,
    // children: [
      // {
         path: '', loadChildren: './master/master.module#MasterModule' ,
        // },
  // ],

    canActivate: [ MsalGuard ]
  },
  { path: 'simulator', component:SimulatorComponent},
];


// General login

/*const routes: Routes = [
  { path: '', loadChildren: './master/master.module#MasterModule' },
];*/


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
