import { Roles } from './roles.model';

// export class UserInformation {
//     userName: string;
//     password: string;
//     email: string;
//     roles: Roles[];
// }

export class RegisterViewModel {
    id:string;
    UserName: string;
    Userid: string;
    Password: string;
    lstRolename: Roles[];
    ConfirmPassword: string;
} 

